import { onMounted, onUnmounted, Ref } from "vue";
import { sendResizeEvent } from "@/parent-interface";

export default function broadcastElementSize(
  element: Ref<HTMLElement | undefined>
) {
  const elementSizeObserver = new ResizeObserver(function (entries) {
    // since we are observing only a single element, so we access the first element in entries array
    const rect = entries[0].contentRect;
    sendResizeEvent({ width: rect.width, height: rect.height });
  });
  onMounted(() => {
    if (!element.value) {
      throw new Error("Missing element for broadcastElementSize");
    }
    elementSizeObserver.observe(element.value);
  });
  onUnmounted(() => elementSizeObserver.disconnect());
  return {};
}
