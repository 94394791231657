<script setup lang="ts">
import { onMounted, ref } from "vue";
import broadcastElementSize from "./composables/broadcastElementSize";

import "@/styles/global.sass";
import "@/styles/type.sass";
import "@/styles/json-form.sass";
import "@wegift/two-components/dist/style.css";
import { sendReadyEvent } from "./parent-interface";

onMounted(sendReadyEvent);

// Send resize events when the main element's size changes
const mainElementRef = ref<HTMLElement>();
broadcastElementSize(mainElementRef);
</script>

<template>
  <div name="resize-element" ref="mainElementRef">
    <router-view />
  </div>
</template>
