import { createApp } from "vue";
import App from "./App.vue";
import { OpenAPI as BrandApprovalApplicationServiceAPI } from "@/api/brand-approval-application-service";
import { OpenAPI as ScionInternalServiceAPI } from "@/api/scion";
import { OpenAPI as BrandApprovalServiceAPI } from "@/api/brand-approval-service";
import emojiFont from "@/assets/TwemojiCountryFlags.woff2";
import { createPinia } from "pinia";
import { addSentry } from "./tracking/sentry";

BrandApprovalApplicationServiceAPI.BASE =
  import.meta.env.VITE_BRAND_APPROVAL_APPLICATION_SERVICE_BASE_URL;
ScionInternalServiceAPI.BASE = import.meta.env.VITE_SCION_BASE_URL;
BrandApprovalServiceAPI.BASE =
  import.meta.env.VITE_BRAND_APPROVAL_SERVICE_BASE_URL;

import router from "./router";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
} from "@fortawesome/vue-fontawesome";
import "./icons";

const app = createApp(App);
const pinia = createPinia();

// Register 'font-awesome-icon' component to use Font Awesome icons across the application
app.component("font-awesome-icon", FontAwesomeIcon);

// Register 'font-awesome-layers' component to use layered Font Awesome icons
app.component("font-awesome-layers", FontAwesomeLayers);

app.use(router);

// Add Sentry error tracking tool to the Vue application and router
addSentry(app, router);

// Check if polyfill for country flag emojis is necessary, mainly for Windows systems
// This allows the use of TwemojiCountryFlags font for displaying flag emojis
if (polyfillCountryFlagEmojis("TwemojiCountryFlags", emojiFont)) {
  // Log a debug message if the country flag emojis were polyfilled
  console.debug("Country flag emojis polyfilled");
}

// Use the Pinia Store management library in the Vue application
app.use(pinia);
app.mount("#app");
