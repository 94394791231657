import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { App } from "vue";
import { Router } from "vue-router";

const environment = import.meta.env.MODE as string;
const domainName = import.meta.env.VITE_DOMAIN_NAME as string;
const sentry_dsn = import.meta.env.VITE_SENTRY_DSN as string;
const isDevelopment = import.meta.env.MODE === "development";

export function addSentry(app: App<Element>, router: Router): void {
  // If the application is in development mode, exit the function
  if (isDevelopment) return;
  Sentry.init({
    app,
    dsn: sentry_dsn,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [domainName],
      }),
      new Sentry.Replay({
        // Tweak masking parameters
        // Instead of masking all input we explicitly mask fields with PII
        // See https://docs.sentry.io/platforms/javascript/session-replay/privacy/
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 0.01,
    environment: environment,
    // This sets the sample rate. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.2,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });
}
